@import url('https://fonts.googleapis.com/css2?family=Carter+One&family=Catamaran&display=swap');

html {
  /* -webkit-appearance: none;
  appearance: auto; */
  box-sizing: border-box;
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
}

body {
  /* -webkit-appearance: none;
  -webkit-letter-spacing: 0.4em; */
  /* appearance: auto; */
  color: #646464;
  height: 100dvh;
  letter-spacing: normal;
  overflow: hidden;
  user-select: none;
  width: 100dvw;
}

*,
*::before,
*::after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
  user-select: none;
  /* border: #b8aaaa 0.1px solid; */
}

#root {
  height: 100%;
  width: 100%;
}

#desktop-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  text-align: center;
  width: 100%;
  justify-content: center;
  background-size: 100vw 100vh;

}

.App {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  text-align: center;
  width: 100%;
}


.initial-content {
  align-items: center;
  animation: fadein 0.4s linear 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-evenly;
  text-align: center;
  transition: all 0.6s;
  width: 80%;
}

.initial-content.blurred {
  filter: blur(12px);
  opacity: 0.5;
}

h1 {
  margin-top: 1rem;
  font-family: 'Carter One', sans-serif;
  font-size: calc(2.1rem + 4dvw);
  height: 2em;
  line-height: 1.1em;
}

.initial-input {
  /* -moz-appearance: none;
  -ms-appearance: none;
  -webkit-appearance: none;
  appearance: auto; */
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 1em;
  box-shadow: 0.6em 0.6em 1.6vh #c5c2c28a;
  color: #6b6b6b;
  font-family: inherit;
  font-size: 4.5vmin;
  font-weight: 800;
  margin: 25px 30px;
  outline: none;
  padding: 1.5em 1.9em;
  text-align: center;
  text-overflow: ellipsis;
  text-transform: uppercase;
  transition: all 0.6s ease;
}

.initial-input:focus {
  /* -webkit-box-shadow: 1em 1em 2.6vh #8f8c8c8a; */
  box-shadow: 1em 1em 2.6vh #8f8c8c8a;
  outline: none;
  transform: scale(1.15);
}

.initial-input::placeholder {
  opacity: 0.2;
}

.initial-input:focus::placeholder {
  visibility: hidden;
}

.initial-input-saved {
  /* -webkit-box-shadow: 0.6em 0.6em 1.5vh #a1b49a; */
  box-shadow: 0.6em 0.6em 1.5vh #a1b49a;
  color: #a1b49a;
}

#buttons-container {
  align-items: center;
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

button img {
  height: 100%;
  opacity: 0.75;
  width: 100%;
}

button {
  background: none;
  border: none;
  color: #383838;
  font-family: 'Secular One', sans-serif;
  max-width: 18vmin;
  text-align: center;
  transition: all 0.5s ease;
}

button:disabled {
  opacity: 0.5;
}

button:hover {
  border: none;
  cursor: pointer;
}

button:disabled:hover {
  opacity: 0.4;
}

.no-hover:hover {
  transform: unset;
}

.bottom-button {
  font-size: 0.8rem;
  font-weight: 800;
  max-width: 150px;
  opacity: 0.4;
}

.bottom-button:hover {
  cursor: pointer;
  opacity: 0.8;
}

#bottom-buttons-container {
  display: flex;
  justify-content: space-evenly;
  min-height: 50px;
  width: 200px;
}

#button-start-again {
  max-width: 200px;
}

#can-undo {
  background-color: #aaaaaa5e;
  border-radius: 0.9em;
  font-size: 0.9rem;
  opacity: 0.7;
  font-weight: 800;

  padding: 0.4em;
  position: relative;
  z-index: 1;
}

#progress-bar {
  background-color: #fff;
  border-radius: 0.9em;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  transition: width 0.1s linear;
  z-index: -100;
}

.modal {
  align-items: center;
  animation: fadein 0.4s linear 1;
  background-color: #f0f0f0;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-evenly;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;
}

.modal-content-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  margin: 1.5rem 0;
  width: 75%;
  margin: 1em;
}

.modal-heading {
  font-size: calc(2rem + 4dvw);
  line-height: 1.1em;
  font-family: 'Carter One', sans-serif;

}

.modal-text {
  font-family: 'Catamaran', sans-serif;
  font-size: calc(1rem + 1dvw);
  font-weight: 400;
  line-height: 1.36em;
  text-align: left;
  margin: 2rem 0;
}

.modal-span {
  border: 1px solid #9d9d9d;
  border-radius: 20%;
  color: #818181;
  font-size: 0.9rem;


}

#modal-span-one {
  background-color: #ffe8ea;
  padding: 0 0.7em;
}

#modal-span-two {
  background-color: #fffad7;
  padding: 0 0.6em;
}

#modal-span-three {
  background-color: #e8fffe;
  padding: 0 0.6em;
}

.modal-btn {
  animation: handshake 1s linear infinite alternate;
  height: 18vmin;
  margin: 0;
  max-width: 18vmin;
  padding: 0;
}

#final-content {
  align-items: center;
  animation: fadein 0.4s linear 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-evenly;
  position: relative;
  text-align: center;
  transition: all 0.5s;
  width: 80%;
}

#final-screens-container {
  align-items: flex-start;
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 55vmin;
  justify-content: center;
  margin: 30px 0;
  position: relative;
  width: 55vmin;
}

.final-screens {
  align-items: center;
  border-radius: 1em;
  box-shadow: 0.6em 0.6em 1.5vh #c5c2c28a;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  position: absolute;
  width: 100%;
}

.final-screen-four {
  background-color: #ffe8ea;
  transform: rotate(0deg);
  z-index: 4;
}

.final-screen-five {
  background-color: #fffad7;
  transform: rotate(10deg);
  z-index: 3;
}

.final-screen-six {
  background-color: #e8fffe;
  transform: rotate(-10deg);
  z-index: 2;
}

.final-screen-seven {
  border: none;
  box-shadow: none;
  z-index: 1;
}

.final-screen-task {
  font-size: 4.5vmin;
  font-weight: 800;
  max-width: 80%;
  text-transform: uppercase;
  transition: transform 0.6s ease;


}

.final-screen-task:hover {
  cursor: pointer;
  opacity: 0.85;
  border-bottom: 0.9px dotted;
  /* transform: scale(1.05); */

}


.fly-away {
  animation: flyAwayAnimation 2s ease 1;
}

footer {
  bottom: 3%;
  font-family: 'Catamaran', sans-serif;
  font-size: 0.5em;
  opacity: 0.5;
  position: absolute;
  text-transform: uppercase;
}

footer a {
  border-bottom: 0.5px solid;
  color: unset;
  padding-bottom: 0.5em;
  text-decoration: none;
}

#bell {
  animation: dong 0.13s infinite;
  display: inline-flex;
}

@keyframes dong {
  0% {
    transform: rotate(-7deg);
  }

  20% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(4deg);
  }

  75% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-7deg);
  }
}

@keyframes handshake {
  0% {
    transform: translateY(-5px);
  }

  100% {
    transform: translateY(5px);
  }
}

@keyframes flyAwayAnimation {
  0% {
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateX(100%) rotate(100deg);
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes movein-four {
  0% {
    transform: translate(-70%, 0);
  }

  100% {
    transform: translate(0, 0);
  }
}

@keyframes movein-five {
  0% {
    transform: translate(70%, 0);
  }

  100% {
    transform: translate(0, 0) rotate(10deg);
  }
}

@keyframes movein-six {
  0% {
    transform: translateY(-70%);
  }

  100% {
    transform: translate(0, 0) rotate(-10deg);
  }
}

/* Desktops and landscape iPads */
@media screen and (min-width: 700px) {

  #root {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .App {
    width: 63%;
    height: 93%;
    max-width: 600px;
    max-height: 900px;

  }

  .initial-input {
    font-size: 2.6vmin;
  }

  .initial-input:focus {
    transform: scale(1.5);
  }

  button {
    max-width: 9vmin;
  }

  h1 {
    font-family: 'Carter One', sans-serif;
    font-size: 4.6rem;
    height: 2.5em;
    line-height: 1.2em;
  }

  #buttons-container {
    align-items: center;
    display: flex;
    justify-content: space-evenly;
    width: 70%;
    margin-bottom: 30px;
  }

  .final-screens {
    width: 250px;
    height: 250px;
  }

  .final-screen-task {
    font-size: 1.5rem;
    font-weight: 800;
  }


  .modal-content-wrapper {
    width: 63%;
    height: 93%;
  }

  .modal-heading {
    font-family: 'Carter One', sans-serif;
    font-size: 4.5rem;
    /* height: 2.1em; */
    line-height: 1.2em;
  }

  .modal-text {
    font-family: 'Catamaran', sans-serif;
    font-size: 1.6rem;
    line-height: 1.2em;
    text-align: left;
  }

  .modal-span {
    border: 1px solid #9d9d9d;
    border-radius: 20%;
    color: #818181;
    font-size: 0.9rem;
  }

  .modal-btn {
    animation: handshake 1s linear infinite alternate;
    height: 9vmin;
    margin: 0;
    max-width: 9vmin;
    padding: 0;
  }

}


@media screen and (max-width: 321px) {

  .modal-heading {
    font-size: 2rem;
    height: 1.5em;
    line-height: 1em;
    margin-bottom: 1em;
  }

  .modal-text {
    font-size: 85%;
  }

  .modal-span {
    font-size: 85%;
  }

  h1 {
    font-family: 'Carter One', sans-serif;
    font-size: 2.1rem;
    height: 1.5em;
    line-height: 1em;
    width: 90%;
  }

}